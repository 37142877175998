<template>
  <div class="app-container">
    <el-card
      shadow="never"
      class="filter-container-card"
    >
      <!-- filter -->
      <div class="filter-container">
        <div class="filter-line">
          <el-select
            v-model="filter.isVisible"
            style="width: 120px"
            class="filter-item"
            placeholder="Is visible"
            clearable="clearable"
            @change="onFilter"
          >
            <el-option
              v-for="item in [
                { key: true, label: 'Visible' },
                { key: false, label: 'Not visible' }
              ]"
              :key="item.key"
              :label="item.label"
              :value="item.key"
            />
          </el-select>

          <el-select
            v-model="filter.isActive"
            style="width: 120px"
            class="filter-item"
            placeholder="Is active"
            clearable="clearable"
            @change="onFilter"
          >
            <el-option
              v-for="item in [
                { key: true, label: 'Active' },
                { key: false, label: 'Not active' }
              ]"
              :key="item.key"
              :label="item.label"
              :value="item.key"
            />
          </el-select>

          <el-input
            v-model="filter.langName"
            placeholder="Search"
            style="width: 220px"
            class="filter-item"
            clearable
            @clear="onFilter"
            @keyup.enter.native="onFilter"
          />

          <el-button
            class="filter-item"
            type="primary"
            icon="el-icon-search"
            :loading="listLoading"
            title="Search"
            @click="onFilter"
          />

          <el-button
            class="filter-item reset-filter"
            icon="el-icon-circle-close"
            :loading="listLoading"
            title="Reset Filter"
            @click="onFilterReset"
          />
        </div>

        <div class="filter-line">
          <el-divider />

          <el-button
            v-permission="['admin']"
            class="filter-item fr"
            type="primary"
            icon="el-icon-circle-plus-outline"
            :loading="listLoading"
            @click="handleNewLanguage"
          >
            New Language
          </el-button>
        </div>
      </div>
    </el-card>

    <!-- table -->
    <el-table
      ref="table"
      v-loading="listLoading"
      :data="list"
      border
      :max-height="maxHeight"
      @sort-change="handleSort"
    >
      <el-table-column
        label="Code"
        sortable="custom"
        prop="langCode"
        width="80"
      >
        <template slot-scope="{ row }">
          <span>{{ row.langCode }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="Name"
        sortable="custom"
        prop="langName"
      >
        <template slot-scope="{ row }">
          <span
            class="link-type"
            @click.stop="handleLanguageEdit(row.langCode)"
          >
            {{ row.langName }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        label="Position"
        sortable="custom"
        prop="position"
        width="120"
      >
        <template slot-scope="{ row }">
          <span>{{ row.position }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="Visible"
        width="80"
      >
        <template slot-scope="{ row }">
          <el-tag :type="row.isVisible ? 'success' : 'danger'">
            {{ row.isVisible ? 'Yes' : 'No' }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column
        label="Active"
        width="80"
      >
        <template slot-scope="{ row }">
          <el-tag :type="row.isActive ? 'success' : 'danger'">
            {{ row.isActive ? 'Yes' : 'No' }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column
        label="Created At"
        width="150"
        sortable="custom"
        prop="createdAt"
      >
        <template slot-scope="{ row }">
          <span>{{ row.createdAt | formatDate }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="Updated At"
        width="150"
        sortable="custom"
        prop="updatedAt"
      >
        <template slot-scope="{ row }">
          <span>{{ row.updatedAt | formatDate }}</span>
        </template>
      </el-table-column>

      <el-table-column
        v-permission="['admin']"
        align="center"
        width="130"
      >
        <template
          slot-scope="{ row }"
        >
          <el-button
            v-permission="['admin']"
            type="primary"
            size="mini"
            circle
            plain
            title="Edit"
            @click.stop="handleLanguageEdit(row.langCode)"
          >
            <i class="far fa-edit"></i>
          </el-button>

          <el-button
            v-permission="['admin']"
            type="danger"
            size="mini"
            circle
            plain
            title="Delete"
            @click.stop="handleLanguageDelete(row.langCode)"
          >
            <i class="far fa-trash"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      v-show="total > 0"
      :pager-count="5"
      class="pagination"
      background
      layout="total, prev, pager, next, sizes"
      :current-page.sync="listQuery.pageNumber"
      :page-size.sync="listQuery.pageSize"
      :total="total"
      @size-change="getList"
      @current-change="getList"
    />

    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      top="25px"
    >
      <div>
        <language-details
          :is-edit-mode="isEditMode"
          :lang-code="activeLanguageCode"
          :visible.sync="dialogVisible"
          @update="getList"
        />
      </div>
    </el-dialog>
  </div>
</template>
<script>

import { listLanguages, deleteLanguage } from '@/api/language';
import maxHeightMixin from '@/mixins/maxHeight';
import linkViewMixin from '@/mixins/linkView';
import permission from '@/directive/permission';
import LanguageDetails from './components/languageDetails';

export default {
  name: 'LanguageList',
  directives: {
    permission,
  },
  components: {
    LanguageDetails,
  },
  mixins: [maxHeightMixin, linkViewMixin],
  data() {
    const pageSize = +localStorage.getItem('defPageSize');
    const defSortBy = 'position';
    const defSortDir = 'asc';

    return {
      list: null,
      total: 0,
      listLoading: true,
      defSortBy,
      defSortDir,
      statuses: [
        { key: 'true', label: 'Yes' },
        { key: 'false', label: 'No' },
      ],
      filter: {
        langName: '',
        isActive: '',
        isVisible: '',
      },
      listQuery: {
        pageNumber: 1,
        pageSize: pageSize || 20,
        sortBy: defSortBy,
        sortDir: defSortDir,
      },
      dialogVisible: false,
      isEditMode: false,
      activeLanguageCode: null,
    };
  },
  watch: {
    $route(to) {
      if (to.name === 'LanguageList') {
        this.getList();
      }
    },
  },
  created() {
    this.getList();
  },
  activated() {
    this.getList(true);
  },
  methods: {
    async getList(refresh = false) {
      try {
        this.listLoading = true;

        if (!refresh) {
          this.list = [];
          this.listQuery.pageNumber = 1;
        }

        const data = await listLanguages(this.listQuery);
        const { items, pageInfo } = data.page;

        this.list = items;
        this.total = pageInfo.itemsAvailable;
        this.listLoading = false;

        localStorage.setItem('defPageSize', this.listQuery.pageSize);
      } catch (e) {
        this.listLoading = false;
      }
    },
    handleSort({ prop, order }) {
      if (order !== null) {
        this.listQuery.sortBy = prop;
        this.listQuery.sortDir = order === 'ascending' ? 'asc' : 'desc';
      } else {
        this.listQuery.sortBy = this.defSortBy;
        this.listQuery.sortDir = this.defSortDir;
      }

      this.getList();
    },
    handleNewLanguage() {
      this.activeLanguageCode = null;
      this.isEditMode = false;
      this.dialogVisible = true;
    },
    handleLanguageEdit(langCode) {
      this.activeLanguageCode = langCode;
      this.isEditMode = true;
      this.dialogVisible = true;
    },
    handleLanguageDelete(langCode) {
      this.$confirm('Are you sure you want to delete?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then(() => {
        deleteLanguage({ langCode }).then(() => {
          this.$message({
            type: 'success',
            message: 'Delete successful',
          });
          this.getList();
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Delete canceled',
        });
      });
    },
    onFilter() {
      const filter = Object.keys(this.filter).reduce((acc, key) => {
        if (this.filter[key] !== '') {
          acc[key] = this.filter[key];
        }

        return acc;
      }, {});

      this.listQuery.filter = JSON.stringify(filter);
      this.getList();
    },
    onFilterReset() {
      this.filter = {
        langName: '',
          isActive: '',
          isVisible: '',
      };

      this.setDefaultFilter();
      this.getList();
    },
    setDefaultFilter() {
      this.listQuery.filter = JSON.stringify({});
    },
  },
};
</script>
