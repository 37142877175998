<template>
  <div class="flex-form">
    <el-form
      ref="languageForm"
      v-loading="loading"
      :model="languageForm"
      label-width="170px"
      :rules="rules"
      autocomplete="off"
    >
      <el-form-item>
        <h2 v-if="isEditMode">
          Edit Language
        </h2>
        <h2 v-else-if="!isEditMode">
          Add Language
        </h2>
      </el-form-item>

      <el-form-item
        v-if="isEditMode"
        label="Code"
      >
        {{ langCode }}
      </el-form-item>

      <el-form-item
        v-if="showLangCode"
        label="Code"
        prop="langCode"
      >
        <el-input v-model="languageForm.langCode" />
      </el-form-item>

      <el-form-item
        label="Name"
        prop="langName"
      >
        <el-input v-model="languageForm.langName" />
      </el-form-item>

      <el-form-item
        label="Position"
        prop="position"
      >
        <el-input v-model="languageForm.position" />
      </el-form-item>

      <el-form-item
        label="Visible"
        prop="visible"
      >
        <el-switch
          v-model="languageForm.isVisible"
        />
      </el-form-item>

      <el-form-item
        label="Active"
        prop="active"
      >
        <el-switch
          v-model="languageForm.isActive"
        />
      </el-form-item>

      <el-form-item class="flex-form__action-buttons">
        <el-button
          type="primary"
          :loading="saving"
          @click="onSubmit"
        >
          Save
        </el-button>

        <el-button @click="onCancel">
          Cancel
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  getLanguage,
  updateLanguage,
  createLanguage,
} from '@/api/language';
import { cloneDeep } from 'lodash';
import { makeUpdateObj } from '@/utils';

export default {
  name: 'LanguageDetails',
  props: {
    isEditMode: {
      default: false,
      type: Boolean,
    },
    langCode: {
      default: null,
      type: String,
    },
    visible: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      saving: false,
      showLangCode: false,
      languageForm: {
        langCode: '',
        langName: '',
        position: 0,
        isVisible: true,
        isActive: true,
      },
      language: {},
      rules: {
        langCode: [{
          required: true,
          trigger: 'blur',
          message: 'Code is required',
        }],
        langName: [{
          required: true,
          trigger: 'blur',
          message: 'Name is required',
        }],
        position: [{
          required: true,
          trigger: 'blur',
          message: 'Position is required',
        }],
      },
    };
  },
  watch: {
    langCode(val) {
      if (val) {
        this.getLanguage(val);
      } else {
        this.resetForm();
      }
    },
  },
  async created() {
    this.resetForm();

    if (this.isEditMode) {
      await this.getLanguage(this.langCode);
    }
  },
  methods: {
    async getLanguage(langCode) {
      try {
        this.resetForm();
        this.loading = true;
        const data = await getLanguage({ langCode });

        this.language = cloneDeep(data.language);
        this.languageForm = data.language;
        this.loading = false;
      } catch (e) {
        this.loading = false;

        this.onCancel();
      }
    },
    async onEditSubmit() {
      this.$refs.languageForm.validate(async (valid) => {
        if (valid) {
          const updatedLanguage = makeUpdateObj(this.language, this.languageForm);
          if (Object.keys(updatedLanguage).length > 0) {
            try {
              this.saving = true;
              updatedLanguage.langCode = this.langCode;

              await updateLanguage(updatedLanguage);

              this.$message({
                type: 'success',
                message: 'Data has been saved successfully',
              });

              await this.getLanguage(this.langCode);
              this.saving = false;

              this.$emit('update');
            } catch (e) {
              this.saving = false;
            }
          }

          this.close();
        }
      });
    },
    async onAddSubmit() {
      this.$refs.languageForm.validate(async (valid) => {
        if (valid) {
          try {
            this.saving = true;
            await createLanguage(this.languageForm);

            this.$message({
              type: 'success',
              message: 'Language has been added successfully',
            });

            this.resetForm();
            this.saving = false;

            this.$emit('update');
            this.close();
          } catch (e) {
            this.saving = false;
          }
        }
      });
    },
    async onSubmit() {
      if (this.isEditMode) {
        await this.onEditSubmit();
      } else {
        await this.onAddSubmit();
      }
    },
    resetForm() {
      this.$nextTick(() => {
        this.showLangCode = !this.isEditMode;
        this.languageForm.langCode = '';
        this.$refs.languageForm.resetFields();
      });
    },
    onCancel() {
      this.close();
    },
    close() {
      this.$emit('update:visible', false);
    },
  },
};
</script>
